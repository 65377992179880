/**
 * App.js
 *
 * @author Dima Dodonov <dimadodonov@icloud.com>
 */

//Global Vars
const $window = $(window);
const $document = $(document);
const $html = $('html');
const $wrapper = $('.wrapper');
const $header = $('.header');
const $main = $('.main');
const $overlay = $('.js-overlay');

const App = {
    init() {
        // console.log('Site init');

        this.nav();
        this.slider();
        this.fancybox();
        this.archive();
        this.Components.init();
    },

    nav() {},
    slider() {
        var swiper = new Swiper('.swiper-container', {
            slidesPerView: 1,
            spaceBetween: 10,
            // init: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                '@0.00': {
                    slidesPerView: 2,
                    spaceBetween: 1,
                },
                '@0.75': {
                    slidesPerView: 3,
                    spaceBetween: 1,
                },
                '@1.00': {
                    slidesPerView: 5,
                    spaceBetween: 1,
                },
                '@1.50': {
                    slidesPerView: 8,
                    spaceBetween: 1,
                },
            },
        });
    },
    fancybox() {
        $('[data-fancybox]').fancybox({
            clickOutside: 'close',
            buttons: [
                //"zoom",
                //"share",
                //"slideShow",
                //"fullScreen",
                //"download",
                //"thumbs",
                'close',
            ],
            protect: true, // РїРѕР»СЊР·РѕРІР°С‚РµР»СЊ РЅРµ РјРѕР¶РµС‚ СЃРѕС…СЂР°РЅРёС‚СЊ РёР·РѕР±СЂР°Р¶РµРЅРёРµ
            // toolbar  : false // СѓР±СЂР°Р»Рё РїР°РЅРµР»СЊ РёРЅСЃС‚СЂСѓРјРµРЅС‚РѕРІ
            mobile: {
                clickContent: 'close',
                clickSlide: 'close',
            },
        });
    },
    archive() {
        $(document).on('click', '.autoservice-archive-view__item', function () {
            var view = $(this).attr('data-view');

            // Добавляем город в localStorage
            localStorage.setItem('choiceView', view);

            if ($('.autoservice-archive-view__item').hasClass('on')) {
                $('.autoservice-archive-view__item').removeClass('on');
            }

            if ($('.autoservice-archive-box').hasClass('on')) {
                $('.autoservice-archive-box').removeClass('on').hide();
            }

            $('.autoservice-archive__' + view)
                .addClass('on')
                .show();
            $(this).addClass('on');
        });

        if (localStorage.getItem('choiceView') !== null) {
            var choiceView = localStorage.getItem('choiceView');

            if ($('.autoservice-archive-view__item').hasClass('on')) {
                $('.autoservice-archive-view__item').removeClass('on');
            }

            if ($('.autoservice-archive-box').hasClass('on')) {
                $('.autoservice-archive-box').removeClass('on').hide();
            }

            $('.autoservice-archive-view__' + choiceView).addClass('on');
            $('.autoservice-archive__' + choiceView)
                .addClass('on')
                .show();
        }
    },
};

App.define = function (namespace) {
    var parts = namespace.split('.'),
        parent = App,
        i;

    //Убрать начальный префикс если это являетсся глобальной переменной
    if (parts[0] == 'App') {
        parts = parts.slice(1);
    }

    //Если в глобальном объекте нет свойства - создать его.
    for (var i = 0; i < parts.length; i++) {
        if (typeof parent[parts[i]] == 'undefined') {
            parent[parts[i]] = {};
        }
        parent = parent[parts[i]];
    }
    return parent;
};

/**

 * Components.js

 *

 * @author Dima Dodonov <dimadodonov@icloud.com>

 */



App.define('Components');



App.Components = (function () {

    function _init() {

        console.log('Site Components Init');

        // App.Components.Input.init();

        App.Components.Ymap.init();

    }



    return {

        init: _init,

    };

})();



App.define('Components.Input');



App.Components.Input = (function () {

    function _init() {

        console.log('Site Components Input Init');

        _initMask();

    }



    function _initMask() {

        //Masked inputmask https://github.com/RobinHerbots/Inputmask

        let phoneMask = new Inputmask('+7 (999) 999-99-99');

        let inputPhone = $('.js-phone-mask');

        if (inputPhone) {

            phoneMask.mask(inputPhone);

        }

    }



    return {

        init: _init,

        initMask: _initMask,

    };

})();



App.define('Components.Ymap');



App.Components.Ymap = (function () {

    function _init() {

        console.log('Site Components Input Init');

        _initYmap();

        _initYmapArchive();

    }



    function _initYmap() {

        if ($('.Ymap').length) {

            ymaps.ready(function () {

                var cord = $('.Ymap').attr('data-cord');

                cord = JSON.parse(cord);

                console.log(cord);

                var myMap = new ymaps.Map(

                        'Ymap',

                        {

                            center: cord,

                            zoom: 16,

                            controls: [

                                // 'zoomControl',

                                // 'searchControl',

                                // 'fullscreenControl',

                            ],

                        },

                        {

                            searchControlProvider: 'yandex#search',

                        }

                    ),

                    myPlacemark = new ymaps.Placemark(

                        myMap.getCenter(),

                        {

                            // hintContent: 'Собственный значок метки',

                            // balloonContent: 'Это красивая метка',

                        },

                        {

                            // Опции.

                            // Необходимо указать данный тип макета.

                            iconLayout: 'default#image',

                            // Своё изображение иконки метки.

                            iconImageHref:

                                '/wp-content/themes/motor23/assets/files/icons/svg/icon--map-auto.svg',

                            // Размеры метки.

                            iconImageSize: [50, 62.16],

                            // Смещение левого верхнего угла иконки относительно

                            // её "ножки" (точки привязки).

                            iconImageOffset: [-25, -58],

                        }

                    );

                myMap.geoObjects.add(myPlacemark);

            });

        }

    }



    function _initYmapArchive() {

        ymaps.ready(init);



        function init() {

            var myMap = new ymaps.Map(

                    'map',

                    {

                        center: [45.03547, 38.975313],

                        zoom: 12,

                        controls: ['zoomControl'],

                        // behaviors: ['drag'],

                    },

                    {

                        searchControlProvider: 'yandex#search',

                    }

                ),

                objectManager = new ymaps.ObjectManager({

                    // Чтобы метки начали кластеризоваться, выставляем опцию.

                    clusterize: true,

                    // ObjectManager принимает те же опции, что и кластеризатор.

                    gridSize: 32,

                    // clusterDisableClickZoom: true,

                });



            // Чтобы задать опции одиночным объектам и кластерам,

            // обратимся к дочерним коллекциям ObjectManager.

            objectManager.objects.options.set(

                'preset',

                'islands#blueCircleDotIconWithCaption'

            );

            objectManager.clusters.options.set(

                'preset',

                'islands#blueClusterIcons'

            );

            myMap.geoObjects.add(objectManager);

            objectManager.add(collection);



            /**

             * Спозиционируем карту так, чтобы на ней были видны все объекты.

             */



            // myMap.setBounds(objectManager.getBounds(), {

            //     checkZoomRange: true,

            // });

        }

    }



    return {

        init: _init,

        initYmap: _initYmap,

        initYmapArchive: _initYmapArchive,

    };

})();



$(function () {



    /*



     * Load More



     */



    $('#misha_loadmore').click(function () {



        $.ajax({



            url: misha_loadmore_params.ajaxurl, // AJAX handler



            data: {



                action: 'loadmorebutton', // the parameter for admin-ajax.php



                query: misha_loadmore_params.posts, // loop parameters passed by wp_localize_script()



                page: misha_loadmore_params.current_page, // current page



            },



            type: 'POST',



            beforeSend: function (xhr) {



                $('#misha_loadmore').text('Загружаю...'); // some type of preloader



            },



            success: function (posts) {



                if (posts) {



                    $('#misha_loadmore').text('Загрузить ещё');



                    $('#misha_posts_wrap').append(posts); // insert new posts



                    misha_loadmore_params.current_page++;







                    if (



                        misha_loadmore_params.current_page ==



                        misha_loadmore_params.max_page



                    )



                        $('#misha_loadmore').hide(); // if last page, HIDE the button



                } else {



                    $('#misha_loadmore').hide(); // if no data, HIDE the button as well



                }



            },



        });



        return false;



    });







    /*



     * Filter



     */



    $('#misha_filters').submit(function () {



        $.ajax({



            url: misha_loadmore_params.ajaxurl,



            data: $('#misha_filters').serialize(), // form data



            dataType: 'json', // this data type allows us to receive objects from the server



            type: 'POST',



            beforeSend: function (xhr) {



                $('#misha_filters').find('button').text('Filtering...');



            },



            success: function (data) {



                // when filter applied:



                // set the current page to 1



                misha_loadmore_params.current_page = 1;







                // set the new query parameters



                misha_loadmore_params.posts = data.posts;







                // set the new max page parameter



                misha_loadmore_params.max_page = data.max_page;







                // change the button label back



                $('#misha_filters').find('button').text('Apply filter');







                // insert the posts to the container



                $('#misha_posts_wrap').html(data.content);







                // hide load more button, if there are not enough posts for the second page



                if (data.max_page < 2) {



                    $('#misha_loadmore').hide();



                } else {



                    $('#misha_loadmore').show();



                }



            },



        });







        // do not submit the form



        return false;



    });







    $(document).on('click', '.sidebar-filter-item', function () {



        if ($('.sidebar-filter-item').hasClass('active')) {



            $('.sidebar-filter-item').removeClass('active');



        }



        $(this).addClass('active');



        var cat = $(this).attr('data-filter-cat');







        console.log(cat);







        var data = {



            action: 'mishafiltercat',



            cat: cat,



        };







        $.ajax({



            url: misha_loadmore_params.ajaxurl,



            data: data, // form data



            dataType: 'json', // this data type allows us to receive objects from the server



            type: 'POST',



            beforeSend: function (xhr) {},



            success: function (data) {



                // when filter applied:



                // set the current page to 1



                misha_loadmore_params.current_page = 1;







                // set the new query parameters



                misha_loadmore_params.posts = data.posts;







                // set the new max page parameter



                misha_loadmore_params.max_page = data.max_page;







                // insert the posts to the container



                $('#misha_posts_wrap').html(data.content);







                // hide load more button, if there are not enough posts for the second page



                if (data.max_page < 2) {



                    $('#misha_loadmore').hide();



                } else {



                    $('#misha_loadmore').show();



                }



            },



        });







        // do not submit the form



        return false;



    });



});







$(function () {
    App.init();
    svg4everybody();
});
